import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d817ed8 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _aec81cf6 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _aa689158 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a7448fa0 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _1a4e29b4 = () => interopDefault(import('../pages/administration/bus_stops.vue' /* webpackChunkName: "pages/administration/bus_stops" */))
const _7a03e7bc = () => interopDefault(import('../pages/administration/buses.vue' /* webpackChunkName: "pages/administration/buses" */))
const _19ef5e82 = () => interopDefault(import('../pages/administration/drivers.vue' /* webpackChunkName: "pages/administration/drivers" */))
const _43ce2872 = () => interopDefault(import('../pages/administration/roads.vue' /* webpackChunkName: "pages/administration/roads" */))
const _25e7b1fc = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _4db0c036 = () => interopDefault(import('../pages/notifications/overview.vue' /* webpackChunkName: "pages/notifications/overview" */))
const _9b329d86 = () => interopDefault(import('../pages/overview/buses.vue' /* webpackChunkName: "pages/overview/buses" */))
const _cf0f254a = () => interopDefault(import('../pages/overview/daily_report.vue' /* webpackChunkName: "pages/overview/daily_report" */))
const _ce8b55cc = () => interopDefault(import('../pages/overview/passengers.vue' /* webpackChunkName: "pages/overview/passengers" */))
const _66bdbee8 = () => interopDefault(import('../pages/overview/reservations.vue' /* webpackChunkName: "pages/overview/reservations" */))
const _0543f74e = () => interopDefault(import('../pages/system/agents.vue' /* webpackChunkName: "pages/system/agents" */))
const _15e9340b = () => interopDefault(import('../pages/system/areas.vue' /* webpackChunkName: "pages/system/areas" */))
const _2d53f104 = () => interopDefault(import('../pages/system/availabilities.vue' /* webpackChunkName: "pages/system/availabilities" */))
const _5d44a28a = () => interopDefault(import('../pages/system/depots.vue' /* webpackChunkName: "pages/system/depots" */))
const _004d5a9d = () => interopDefault(import('../pages/system/holiday.vue' /* webpackChunkName: "pages/system/holiday" */))
const _e0b3d858 = () => interopDefault(import('../pages/system/operators.vue' /* webpackChunkName: "pages/system/operators" */))
const _26b83ed6 = () => interopDefault(import('../pages/system/prices.vue' /* webpackChunkName: "pages/system/prices" */))
const _59551924 = () => interopDefault(import('../pages/system/statistics.vue' /* webpackChunkName: "pages/system/statistics" */))
const _363f49b8 = () => interopDefault(import('../pages/systemlog/overview.vue' /* webpackChunkName: "pages/systemlog/overview" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forget_password/",
    component: _2d817ed8,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _aec81cf6,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _aa689158,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _a7448fa0,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/administration/bus_stops/",
    component: _1a4e29b4,
    pathToRegexpOptions: {"strict":true},
    name: "administration-bus_stops"
  }, {
    path: "/administration/buses/",
    component: _7a03e7bc,
    pathToRegexpOptions: {"strict":true},
    name: "administration-buses"
  }, {
    path: "/administration/drivers/",
    component: _19ef5e82,
    pathToRegexpOptions: {"strict":true},
    name: "administration-drivers"
  }, {
    path: "/administration/roads/",
    component: _43ce2872,
    pathToRegexpOptions: {"strict":true},
    name: "administration-roads"
  }, {
    path: "/administration/users/",
    component: _25e7b1fc,
    pathToRegexpOptions: {"strict":true},
    name: "administration-users"
  }, {
    path: "/notifications/overview/",
    component: _4db0c036,
    pathToRegexpOptions: {"strict":true},
    name: "notifications-overview"
  }, {
    path: "/overview/buses/",
    component: _9b329d86,
    pathToRegexpOptions: {"strict":true},
    name: "overview-buses"
  }, {
    path: "/overview/daily_report/",
    component: _cf0f254a,
    pathToRegexpOptions: {"strict":true},
    name: "overview-daily_report"
  }, {
    path: "/overview/passengers/",
    component: _ce8b55cc,
    pathToRegexpOptions: {"strict":true},
    name: "overview-passengers"
  }, {
    path: "/overview/reservations/",
    component: _66bdbee8,
    pathToRegexpOptions: {"strict":true},
    name: "overview-reservations"
  }, {
    path: "/system/agents/",
    component: _0543f74e,
    pathToRegexpOptions: {"strict":true},
    name: "system-agents"
  }, {
    path: "/system/areas/",
    component: _15e9340b,
    pathToRegexpOptions: {"strict":true},
    name: "system-areas"
  }, {
    path: "/system/availabilities/",
    component: _2d53f104,
    pathToRegexpOptions: {"strict":true},
    name: "system-availabilities"
  }, {
    path: "/system/depots/",
    component: _5d44a28a,
    pathToRegexpOptions: {"strict":true},
    name: "system-depots"
  }, {
    path: "/system/holiday/",
    component: _004d5a9d,
    pathToRegexpOptions: {"strict":true},
    name: "system-holiday"
  }, {
    path: "/system/operators/",
    component: _e0b3d858,
    pathToRegexpOptions: {"strict":true},
    name: "system-operators"
  }, {
    path: "/system/prices/",
    component: _26b83ed6,
    pathToRegexpOptions: {"strict":true},
    name: "system-prices"
  }, {
    path: "/system/statistics/",
    component: _59551924,
    pathToRegexpOptions: {"strict":true},
    name: "system-statistics"
  }, {
    path: "/systemlog/overview/",
    component: _363f49b8,
    pathToRegexpOptions: {"strict":true},
    name: "systemlog-overview"
  }, {
    path: "/",
    redirect: "/overview/buses/",
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
